<template>
	<div class="justify-center align-center">
		<v-icon color="success" class="mt-n1 mr-1">
			mdi-check
		</v-icon>
		<b class="success--text mr-8">{{ numberOfApprovals }} / {{ numberOfReviewers }}</b>
		<ReviewAvatar
			v-for="item in visibleCollaborators"
			:key="item._id"
			:collaborator="item"
			class="mr-2 ml-n4"
			style="outline: 2px solid #E5E5E5; cursor: pointer;"
			@click="showReviewers = true"
		/>
		<ChamaileonPrimaryButton
			v-if="!isRequester && !isEditor && !hasCollaboratorApproved"
			class="ml-4"
			@click="showApprove"
		>
			{{ $t("review.approve") }}
		</ChamaileonPrimaryButton>
		<ChamaileonSecondaryErrorButton
			v-else-if="!isRequester && !isEditor && hasCollaboratorApproved"
			class="ml-4"
			@click="showDismiss"
		>
			{{ $t("review.dismiss") }}
		</ChamaileonSecondaryErrorButton>

		<ReviewModalStatus
			:show="showReviewers"
			:collaborators="collaborators"
			:comments="comments"
			@close="showReviewers = false"
		/>
	</div>
</template>

<script>
import { useReviewStore } from "@/store/reviewStore";
import { mapStores } from "pinia";

export default {
	name: "ReviewHeaderStatus",
	data() {
		return {
			showReviewers: false,
		};
	},
	computed: {
		...mapStores(useReviewStore),
		comments() {
			return this.reviewStore.comments;
		},
		collaborators() {
			return this.reviewStore.collaborators;
		},
		visibleCollaborators() {
			return this.collaborators.filter(c => c?.role !== "editor").slice(1, 4);
		},
		numberOfApprovals() {
			return this.collaborators.filter(el => el.status === "approved").length;
		},
		numberOfReviewers() {
			return this.collaborators.slice(1).filter(c => c?.role !== "editor").length;
		},
		hasCollaboratorApproved() {
			return this.collaborators?.some(el => el._id === this.reviewStore.collaboratorId && el.status === "approved");
		},
		isRequester() {
			return this.reviewStore?.collaboratorId === this.collaborators?.[0]?._id;
		},
		isEditor() {
			return this.collaborators?.find(el => el._id === this.reviewStore.collaboratorId)?.role === "editor";
		},
	},
	methods: {
		showApprove() {
			const hasUnresolvedComments = this.comments.some(el => !el.resolved);
			if (hasUnresolvedComments) this.reviewStore.showApproveConfirmation = true;
			else {
				this.reviewStore.showApproveConfirmation = false;
				this.reviewStore.showApprove = true;
			}
		},
		showDismiss() {
			this.reviewStore.showDismissConfirmation = true;
		},
	},
};
</script>
