import Vue from "vue";
import VueI18n from "vue-i18n";
import enLocaleMessages from "@/locales/en.json";

Vue.use(VueI18n);

const i18n = new VueI18n({
	fallbackLocale: "en",
	messages: { en: enLocaleMessages },
	silentFallbackWarn: true, // ignore missing translation key warnings
});

export default i18n;

// Language files
export async function loadLocaleMessages(locale) {
	let returnLocale = {};
	try {
		await import(`@/locales/${locale}.json`).then((lang) => {
			returnLocale = lang.default;
		});

		return returnLocale;
	} catch (e) {
		console.warn(`The file "${locale}".json could not be loaded.`);
		console.error(e);
	}
}

export async function setLocale(locale, messages) {
	i18n.locale = locale;
	if (!messages) {
		i18n.setLocaleMessage(locale, await loadLocaleMessages(locale));
	} else {
		i18n.setLocaleMessage(locale, messages);
	}
}
