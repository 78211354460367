<template>
	<div class="d-flex flex-column">
		<ChamaileonTabs
			class="mb-6"
			:tabs="tabs"
			:active-tab="activeTab"
			:on-tab-click="onTabClick"
		/>
		<div v-if="!filteredComments.length">
			<p class="text-body-1 text-center" style="letter-spacing: 0 !important;">
				{{ $t("review.comments.noComments") }}
			</p>
			<p class="text-body-2 text-center px-2">
				{{ $t("review.info") }}
			</p>
		</div>
		<ReviewCommentList
			v-else
			:comments="filteredComments"
			:is-resolved="activeTab === 'resolved'"
		/>
		<ReviewCommentAdd
			v-if="activeTab === 'open' && selectedEid && !selectedComment"
			:collaborators="collaborators"
		/>
	</div>
</template>

<script>
import { useReviewStore } from "@/store/reviewStore";
import { mapStores } from "pinia";

export default {
	name: "ReviewComments",
	data() {
		return {
			activeTab: "open",
			tabs: [
				{ id: "open", name: this.$t("general.open") },
				{ id: "resolved", name: this.$t("general.resolved") },
			],
		};
	},
	computed: {
		...mapStores(useReviewStore),
		collaborators() {
			return this.reviewStore.collaborators;
		},
		comments() {
			return this.reviewStore.comments;
		},
		selectedEid() {
			return this.reviewStore.selectedEid;
		},
		selectedComment() {
			return this.reviewStore.selectedComment;
		},
		isSmallDevice() {
			return this.$vuetify.breakpoint.width < 768;
		},
		filteredComments() {
			return this.comments.filter(el => this.activeTab === "open" ? !el.resolved : el.resolved);
		},
	},
	methods: {
		onTabClick(tab) {
			this.activeTab = tab.id;
		},
	},
};
</script>

<style>
.v-tabs > .v-tabs-bar {
	background-color: transparent !important;
}
</style>
