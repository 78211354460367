<template>
	<v-navigation-drawer
		app
		clipped
		permanent
		right
		floating
		width="280"
		class="py-6 mr-4"
		:color="color"
	>
		<v-layout
			justify-space-between
			fill-height
			column
		>
			<div class="d-flex flex-column mb-6">
				<p class="text-subtitle-2" style="font-size: 1.125rem !important">
					{{ $t("review.comments.title") }}
				</p>
				<ReviewComments />
			</div>
		</v-layout>
	</v-navigation-drawer>
</template>

<script>
import { useWhitelabelStore } from "@/store/whitelabelStore";
import { mapStores } from "pinia";

export default {
	name: "ReviewSidebar",
	computed: {
		...mapStores(useWhitelabelStore),
		color() {
			return !this.whitelabelStore.isDefault ? "background" : "";
		},
	},
};
</script>
