export const isObject = obj => obj && typeof obj === "object" && !Array.isArray(obj) && obj !== null;

export const isDefined = variable => typeof variable !== "undefined";

export const getBaseRoute = (config, name) => {
	return config?.backends?.[name] || "";
};

export const queryParamsToString = (obj) => {
	if (!isObject(obj) || Object.keys(obj).length === 0) return "";
	let result = "?";

	Object.keys(obj).forEach((key) => {
		if (obj[key] === null || obj[key] === undefined) return;
		result += `${result === "?" ? "" : "&"}${key}=${typeof obj[key] === "string" ? obj[key] : JSON.stringify(obj[key])}`;
	});

	return result;
};

export const replaceStringIds = (str, ids) => {
	let result = str;
	ids && Object.keys(ids).forEach((key) => {
		const regex = new RegExp(`:${key}`);
		result = result.replace(regex, ids[key]);
	});

	return result;
};

export const formatType = (type) => {
	const responseValues = {
		json: "json",
		form: "formData",
		text: "text",
		blob: "blob",
	};
	const requestValues = {
		json: "application/json",
		form: "application/x-www-form-urlencoded",
		text: "text/plain",
		blob: "application/octet-stream",
	};

	let requestType;
	let responseType;

	if (typeof type === "string") {
		requestType = requestValues?.[type] || "";
		responseType = responseValues?.[type] || "";
	} else if (isObject(type)) {
		requestType = requestValues?.[type?.request] || "";
		responseType = responseValues?.[type?.response] || "";
	} else {
		throw new Error("type must be a string or an object");
	}

	if (requestType === "" || responseType === "") throw new Error(`types must be one of: ${Object.keys(responseValues).join(", ")}`);

	return { requestType, responseType };
};
