<template>
	<div>
		<v-sheet
			v-for="thread in comments"
			:key="thread._id"
			:class="{ 'mb-4 pa-4 text-subtitle-2': true, 'grey--text': isResolved }"
			:style="threadStyle(thread._id)"
			@click="selectComment($event, thread)"
		>
			<ReviewCommentListItem
				:comment="rootComment(thread)"
				:collaborator="collaborator(rootComment(thread)?.collaboratorId)"
				:collaborator-id="collaboratorId"
				:avatar-url="reviewStore.avatarUrl(rootComment(thread)?.collaboratorId)"
				:is-editing="isEditing"
				:is-resolved="isResolved"
				:is-root="true"
				:thread-id="thread._id"
				@openEdit="isEditing = $event"
				@closeEdit="isEditing = null"
				@resolve="resolveThread"
				@reopen="reopenThread"
				@update="updateComment"
			/>

			<!-- Replies -->
			<div v-for="reply in thread.comments.slice(1)" :key="reply._id">
				<v-divider class="my-4" />

				<ReviewCommentListItem
					:comment="reply"
					:collaborator="collaborator(reply.collaboratorId)"
					:collaborator-id="collaboratorId"
					:avatar-url="reviewStore.avatarUrl(reply.collaboratorId)"
					:is-editing="isEditing"
					:thread-id="thread._id"
					@openEdit="isEditing = $event"
					@closeEdit="isEditing = null"
					@resolve="resolveThread"
					@reopen="reopenThread"
					@update="updateComment"
				/>
			</div>

			<!-- Reply Form -->
			<div v-if="canReply(thread)" class="d-flex align-center mt-4">
				<ReviewAvatar
					:collaborator="collaborator(collaboratorId)"
					:size="24"
					class="mr-2"
				/>
				<ChamaileonPrimaryTextInput
					hide-details
					append-icon="mdi-send"
					class="font-weight-regular flex"
					:input-value="newComment"
					:placeholder="$t('review.comments.add')"
					@update:inputValue="newComment = $event"
					@append="addReply(thread._id)"
					@enterPressed="addReply(thread._id)"
				/>
			</div>
		</v-sheet>
	</div>
</template>
<script>
import { mapStores } from "pinia";
import { useReviewStore } from "@/store/reviewStore";
import jsonPreviewCore from "@edmdesigner/json-preview-core";

export default {
	name: "ReviewCommentList",
	props: {
		comments: {
			type: Array,
			required: true,
		},
		isResolved: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			newComment: "",
			isEditing: "",
			showDismissConfirmation: false,
			threadIdToReopen: "",
		};
	},
	computed: {
		...mapStores(useReviewStore),
		collaboratorId() {
			return this.reviewStore.collaboratorId;
		},
		selectedComment() {
			return this.reviewStore.selectedComment;
		},
		collaborators() {
			return this.reviewStore.collaborators;
		},
		hasCollaboratorApproved() {
			return this.collaborators?.some(el => el._id === this.reviewStore.collaboratorId && el.status === "approved");
		},
		isLoading() {
			return this.reviewStore.isLoading;
		},
	},
	methods: {
		rootComment(thread) {
			return thread.comments[0];
		},
		threadStyle(threadId) {
			if (this.isResolved) return "border-radius: 6px; border: 1px solid #e5e5e5";

			return threadId === this.selectedComment
				? "border-radius: 6px; border: 1px solid var(--v-primary-base)"
				: "border-radius: 6px; border: 1px solid #e5e5e5";
		},
		collaborator(collaboratorId) {
			return this.collaborators.find(c => c._id === collaboratorId);
		},
		selectComment(event, comment) {
			if (this.isResolved) return;

			const clickableTags = ["BUTTON", "INPUT", "I", "SPAN"];
			if (clickableTags.includes(event.target.tagName)) return;

			if (event.target.className.includes("v-input")) return;

			if (comment._id === this.selectedComment) {
				this.reviewStore.unselectElement();
				return;
			}

			this.reviewStore.clickOrigin = "comment";
			this.reviewStore.selectedComment = comment._id;
			jsonPreviewCore.clickElement(comment.eid);
		},
		async updateComment(comment, newContent) {
			if (!newContent) return;
			if (this.isLoading) return;
			await this.reviewStore.updateComment(comment.threadId, comment._id, { content: newContent });
			this.isEditing = null;
		},
		async resolveThread(threadId) {
			const openThreads = this.comments.filter(el => !el.resolved);

			await this.reviewStore.updateThread(threadId, true);

			const isRequester = this.reviewStore.collaboratorId === this.collaborators[0]?._id;
			if (openThreads.length === 1 && !isRequester) this.reviewStore.showApproveConfirmation = true;
		},
		async reopenThread(threadId) {
			if (this.hasCollaboratorApproved) {
				this.reviewStore.showDismissConfirmation = true;
				this.reviewStore.threadIdToReopen = threadId;
			} else {
				await this.reviewStore.updateThread(threadId, false);
			}
		},
		canReply(comment) {
			if (this.isEditing) return false;
			return this.selectedComment === comment._id;
		},
		async addReply(threadId) {
			if (!this.newComment) return;

			const newComment = {
				reviewId: this.reviewStore.current?._id,
				workspaceId: this.reviewStore.current?.workspaceId,
				collaboratorId: this.collaboratorId,
				eid: this.reviewStore.selectedEid,
				content: this.newComment,
				threadId,
			};

			if (this.hasCollaboratorApproved) {
				this.reviewStore.showDismissConfirmation = true;
				this.reviewStore.newComment = newComment;
			} else {
				await this.reviewStore.createComment(newComment);
			}

			this.newComment = "";
		},
	},
};
</script>
