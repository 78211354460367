<template>
	<v-dialog
		:value="true"
		max-width="320"
		persistent
	>
		<v-card class="rounded">
			<v-container class="d-flex flex-column align-center py-8 white">
				<v-card-title class="pa-0 ma-0 mb-4 justify-center font-weight-light text-h6">
					<div class="pt-0" style="word-break: normal;">
						{{ errorText }}
					</div>
				</v-card-title>

				<v-icon size="32" color="error">
					mdi-alert-circle-outline
				</v-icon>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>
import { useShareStore } from "@/store/shareStore";
import { mapStores } from "pinia";

export default {
	name: "ErrorDialog",
	computed: {
		...mapStores(useShareStore),
		errorText() {
			return this.shareStore.errorText;
		},
	},
};
</script>
