<template>
	<ChamaileonModal
		:show="show"
		max-width="750"
		@close="close"
	>
		<template #title>
			<h4 class="text-h6">
				{{ $t('reportAbuse.name') }}
			</h4>
		</template>
		<template #content>
			<p class="text-body-1 black--text mb-0" style="letter-spacing: 0 !important;">
				{{ $t("reportAbuse.issue.title") }}
			</p>
			<v-radio-group v-model="selectedIssue">
				<v-radio
					v-for="issue in issueOptions"
					:key="issue.value"
					:label="issue.text"
					:value="issue.value"
				/>
			</v-radio-group>
			<p class="text-body-1 black--text mb-0" style="letter-spacing: 0 !important;">
				{{ $t("reportAbuse.medium.title") }}
			</p>
			<v-radio-group v-model="selectedMedium">
				<v-radio
					v-for="medium in mediumOptions"
					:key="medium.value"
					:label="medium.text"
					:value="medium.value"
				/>
			</v-radio-group>
			<ChamaileonPrimaryTextInput
				:hide-details="true"
				:input-value="details"
				:label="$t('reportAbuse.details.label')"
				:placeholder="$t('reportAbuse.details.placeholder')"
				@update:inputValue="details = $event"
			/>
		</template>
		<template #buttons>
			<ChamaileonPrimaryButton :disabled="isButtonDisabled" @click="reportAbuse">
				{{ $t('reportAbuse.name') }}
			</ChamaileonPrimaryButton>
		</template>
	</ChamaileonModal>
</template>

<script>
import { useDocumentStore } from "@/store/documentStore";
import { mapStores } from "pinia";

export default {
	name: "PreviewReportAbuse",
	props: {
		show: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			selectedIssue: null,
			selectedMedium: null,
			details: "",
			issueOptions: [
				{ value: "spam", text: this.$t("reportAbuse.issue.spam") },
				{ value: "phishing", text: this.$t("reportAbuse.issue.phishing") },
				{ value: "malware", text: this.$t("reportAbuse.issue.malware") },
				{ value: "harmful", text: this.$t("reportAbuse.issue.harmful") },
			],
			mediumOptions: [
				{ value: "email", text: this.$t("reportAbuse.medium.email") },
				{ value: "chat", text: this.$t("reportAbuse.medium.chat") },
				{ value: "sms", text: this.$t("reportAbuse.medium.sms") },
				{ value: "website", text: this.$t("reportAbuse.medium.website") },
				{ value: "mobileApp", text: this.$t("reportAbuse.medium.mobileApp") },
			],
		};
	},
	computed: {
		...mapStores(useDocumentStore),
		isButtonDisabled() {
			return !this.selectedIssue || !this.selectedMedium;
		},
	},
	methods: {
		close() {
			this.selectedMedium = null;
			this.selectedIssue = null;
			this.details = "";
			this.$emit("close");
		},
		async reportAbuse() {
			const answers = {
				issue: this.issueOptions.find(option => option.value === this.selectedIssue).text,
				medium: this.mediumOptions.find(option => option.value === this.selectedMedium).text,
				details: this.details,
			};
			const clientData = {
				userAgent: window && window.navigator && window.navigator.userAgent,
				appVersion: window && window.navigator && window.navigator.appVersion,
				platform: window && window.navigator && window.navigator.platform,
			};
			const res = await this.documentStore.reportAbuse(answers, clientData);

			if (res) this.close();
		},
	},
};
</script>
