import { createConnector } from "./connectorBase";

const crudMethods = ["list", "getOne", "createOne", "patchOne", "updateOne", "deleteOne"];
const httpMethods = {
	list: "GET",
	getOne: "GET",
	createOne: "POST",
	patchOne: "PATCH",
	updateOne: "PUT",
	deleteOne: "DELETE",
};

export default function createCrudConnector({ currentId, methods = crudMethods, route: { base, path = "/" }, types }) {
	const connectors = {};

	if (methods.some(method => !crudMethods.includes(method))) throw new Error("Method must be one of: list, getOne, createOne, patchOne, updateOne, deleteOne");

	methods.forEach((method) => {
		const connector = createConnector({
			type: types?.[method] || "json",
			method: httpMethods[method],
			route: {
				base,
				path: (method !== "list" && currentId) ? `${path}/:${currentId}` : path,
			},
		});
		connectors[method] = connector;
	});

	return connectors;
}
