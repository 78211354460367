import Vue from "vue";
import App from "@/App.vue";

// plugins
import i18n, { setLocale } from "@/plugins/i18n";
import vuetify from "@/plugins/vuetify";
import pinia from "@/plugins/pinia";
import router from "@/plugins/router";
import initSentry from "@/plugins/sentry";

// utils
import updateConnectorBaseConfig from "@/utils/connectors/connectorBase";

// modules
import chamaileonFrontendModules from "@edmdesigner/chamaileon-frontend-modules";
import chamaileonFrontendUtils from "@edmdesigner/chamaileon-frontend-utils";

(async () => {
	Vue.config.productionTip = false;
	Vue.prototype.$chamaileon = { envName: "dev" };

	const locale = "en"; // Later we can have it from param or config
	setLocale(locale);

	initSentry();

	// LOAD CONFIGURATION FILES
	const host = window.location.host;
	if (host.includes("localhost")) Vue.prototype.$chamaileon.envName = "dev";
	else if (host.includes("test") || host.includes("staging")) Vue.prototype.$chamaileon.envName = "staging";
	else Vue.prototype.$chamaileon.envName = "prod";

	const environmentConfigUrl = `${Vue.prototype.$chamaileon.envName.includes("dev")
		? "http://localhost:50004/configs"
		: "https://config.chamaileon.io"}/%ENVNAME%.json`;

	const chamaileonEnvironmentConfig = await chamaileonFrontendUtils.loadEnvironmentConfig({
		environmentConfigUrl,
		environmentName: Vue.prototype.$chamaileon.envName,
	});

	Vue.prototype.$chamaileon.environmentConfig = chamaileonEnvironmentConfig;
	updateConnectorBaseConfig({ actions: {}, chamaileonEnvironmentConfig });

	// load chamaileon logo create script
	chamaileonFrontendUtils.addScript("https://cdn.chamaileon.io/assets/createLogoWithText.js");

	Vue.use(chamaileonFrontendModules);

	new Vue({
		pinia,
		vuetify,
		i18n,
		router,
		render: h => h(App),
	}).$mount("#app");
})();
