<template>
	<v-avatar
		:color="reviewStore.avatarColor(collaborator._id)"
		:size="size"
		v-bind="$attrs"
		v-on="$listeners"
	>
		<img
			v-if="collaborator.userId"
			:src="reviewStore.avatarUrl(collaborator.userId)"
			:title="collaborator.email"
		>
		<span v-else class="white--text">
			{{ firstDigit(collaborator.email).toUpperCase() }}
		</span>
	</v-avatar>
</template>

<script>
import { useReviewStore } from "@/store/reviewStore";
import { mapStores } from "pinia";

export default {
	name: "ReviewAvatar",
	props: {
		collaborator: {
			type: Object,
			required: true,
		},
		size: {
			type: Number,
			default: 32,
		},
	},
	computed: {
		...mapStores(useReviewStore),
	},
	methods: {
		firstDigit(email) {
			return email[0].toUpperCase();
		},
	},
};
</script>
