import { formatType, getBaseRoute, isObject, isDefined, queryParamsToString, replaceStringIds } from "./helpers";

const localActions = {};
let environmentConfig;

const removeTrailingSlash = url => url.endsWith("/") ? url.slice(0, -1) : url;

export const createConnector = ({ type = "json", method = "GET", route: { base, path = "/" } }) => {
	const { requestType, responseType } = formatType(type);

	return async ({ body, query, errorHandler, ids }) => {
		try {
			if (isDefined(localActions.checkTokens)) await localActions.checkTokens();
			const pathName = replaceStringIds(path, ids);
			const url = encodeURI(`${getBaseRoute(environmentConfig, base)}${pathName}${queryParamsToString(query)}`);

			const sanitizedUrl = new URL(removeTrailingSlash(url)).toString();

			const tokenHeaders = isDefined(localActions.getTokens)
				? await localActions.getTokens()
				: {};

			const isFormRequest = type === "form" || type?.request === "form";

			const requestOpts = {
				method,
				credentials: "include", // TODO remove when header auth is implemented on the backend
				headers: {
					...(isFormRequest ? {} : { "Content-Type": requestType }),
					...tokenHeaders,
				},
				body: isFormRequest ? body : JSON.stringify(body),
			};
			if (method === "GET" || method === "HEAD") delete requestOpts.body;

			const response = await fetch(sanitizedUrl, requestOpts);

			const data = await response[responseType]();

			if (data?.error) throw new Error(data?.error?.code);

			return data;
		} catch (err) {
			if (isDefined(errorHandler)) errorHandler(err);
			else throw err;
		}
	};
};

export default function updateConnectorBaseConfig({ actions: { getTokens, checkTokens }, chamaileonEnvironmentConfig }) {
	if (isDefined(getTokens) && typeof getTokens !== "function") {
		throw new Error("getTokens action must be a function");
	}
	if (isDefined(checkTokens) && typeof checkTokens !== "function") {
		throw new Error("checkTokens action must be a function");
	}

	if (isDefined(checkTokens)) localActions.checkTokens = checkTokens;
	if (isDefined(getTokens)) localActions.getTokens = getTokens;

	if (!isObject(chamaileonEnvironmentConfig) && typeof chamaileonEnvironmentConfig !== "undefined") {
		throw new Error("chamaileonEnvironmentConfig must be an object");
	}

	if (chamaileonEnvironmentConfig) environmentConfig = chamaileonEnvironmentConfig;
}
