<template>
	<ChamaileonModal
		:show="show"
		max-width="750"
		persistent
	>
		<template #title>
			<h4 class="text-h6">
				{{ $t('securityWarning.name') }}
			</h4>
		</template>
		<template #content>
			<p class="text-body-1 black--text mb-0" style="letter-spacing: 0 !important;">
				{{ $t('securityWarning.line1') }}
			</p>
			<p class="text-body-1 black--text mb-0 mt-2" style="letter-spacing: 0 !important;">
				{{ $t('securityWarning.emailName') }}: {{ documentStore.current.title }}
			</p>
			<p class="text-body-1 black--text mb-0" style="letter-spacing: 0 !important;">
				{{ $t('securityWarning.workspaceName') }}: {{ documentStore.current.security.workspaceName }}
			</p>
			<p class="text-body-1 black--text mb-0" style="letter-spacing: 0 !important;">
				{{ $t('securityWarning.workspaceOwnerName') }}: {{ documentStore.current.security.owner.displayName }}
			</p>
			<p class="text-body-1 black--text mb-0" style="letter-spacing: 0 !important;">
				{{ $t('securityWarning.workspaceOwnerEmail') }}: {{ documentStore.current.security.owner.email }}
			</p>
			<p
				v-if="whitelabelStore.current?.disableDirectLinkClick"
				class="text-body-1 black--text mt-4 mb-0"
				style="letter-spacing: 0 !important;"
			>
				{{ $t('securityWarning.linkWarning') }}
			</p>
			<p class="text-body-1 black--text mt-4 mb-0" style="letter-spacing: 0 !important;">
				{{ $t('securityWarning.trustWarning') }}
			</p>
			<v-checkbox
				:label="$t('securityWarning.trustWorkspace')"
				:value="trust"
				class="my-0 py-0 mt-2"
				@change="trust = !trust"
			/>
			<v-checkbox
				:label="$t('securityWarning.saveTrust')"
				:value="save"
				:disabled="!trust"
				class="my-0 py-0"
				@change="save = !save"
			/>
		</template>
		<template #buttons>
			<ChamaileonTextErrorButton @click="report">
				{{ $t('securityWarning.report') }}
			</ChamaileonTextErrorButton>
			<ChamaileonPrimaryButton :disabled="!trust" @click="continueToEmail">
				{{ $t('securityWarning.continue') }}
			</ChamaileonPrimaryButton>
		</template>
	</ChamaileonModal>
</template>

<script>
import { useDocumentStore } from "@/store/documentStore";
import { useWhitelabelStore } from "@/store/whitelabelStore";
import { mapStores } from "pinia";
export default {
	name: "PreviewSecurityWarning",
	props: {
		show: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			trust: false,
			save: false,
		};
	},
	computed: {
		...mapStores(useDocumentStore, useWhitelabelStore),
	},
	methods: {
		continueToEmail() {
			if (this.save) {
				if (localStorage) {
					localStorage.trustedWorkspaces = `${localStorage.trustedWorkspaces || ""} ${this.documentStore.current.workspaceId}`;
				}
			}
			this.$emit("close");
		},
		report() {
			this.$emit("openAbuse");
		},
	},

};
</script>
