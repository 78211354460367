<template>
	<v-bottom-sheet v-model="reviewStore.isBottomSheetOpen">
		<v-sheet
			style="border-top-left-radius: 8px; border-top-right-radius: 8px;"
			height="80vh"
		>
			<div class="py-4" style="position: relative;">
				<p class="text-subtitle-2 text-center mb-0" style="font-size: 1.125rem !important">
					{{ $t("review.comments.title") }}
				</p>
				<v-btn
					icon
					text
					style="position: absolute; right: 1rem; top: .625rem;"
					:title="$t('general.close')"
					@click="reviewStore.isBottomSheetOpen = false"
				>
					<v-icon class="float-right">
						mdi-close
					</v-icon>
				</v-btn>
			</div>
			<ReviewComments class="px-2" />
		</v-sheet>
	</v-bottom-sheet>
</template>

<script>
import { useReviewStore } from "@/store/reviewStore";
import { mapStores } from "pinia";

export default {
	name: "ReviewBottomSheet",
	computed: {
		...mapStores(useReviewStore),
	},
};
</script>
