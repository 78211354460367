import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
	// TODO: update this and the logos with whitelabel (should fallback to this if not provided)
	theme: {
		themes: {
			light: {
				primary: "#00c0e7",
				secondary: "#009f4a",
				error: "#ff5546",
				info: "#2196F3",
				warning: "#FFC107",
				success: "#50D791",
				input: "#1c1b1f",
			},
		},
		options: {
			customProperties: true,
			variations: true,
		},
		icons: {
			iconfont: "mdi",
		},
	},
});
