<template>
	<div>
		<!-- Comment Header -->
		<div class="d-flex align-center mb-4">
			<ReviewAvatar :collaborator="collaborator" />
			<div class="ml-2 flex">
				<p class="mb-n1 ellipsis" :style="nameStyle">
					{{ reviewStore.name({ collaborator }) }}
				</p>
				<p class="grey--text font-weight-regular mb-0" :style="nameStyle">
					{{ formatDate(comment.createdAt) }}
				</p>
			</div>

			<!-- Comment Actions -->
			<div v-if="canPerformActions" class="d-flex">
				<v-tooltip v-if="isRoot" left>
					<template #activator="{ on, attrs }">
						<v-btn
							text
							icon
							class="ml-auto"
							color="primary"
							v-bind="attrs"
							v-on="on"
							@click="$emit('resolve', threadId)"
						>
							<v-icon>mdi-check</v-icon>
						</v-btn>
					</template>
					{{ $t("review.resolve") }}
				</v-tooltip>
				<v-tooltip v-if="canPerformActions" left>
					<template #activator="{ on, attrs }">
						<v-btn
							text
							icon
							class="ml-auto"
							v-bind="attrs"
							v-on="on"
							@click="$emit('openEdit', comment._id)"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</template>
					{{ $t("review.edit") }}
				</v-tooltip>
			</div>

			<!-- Re-open action -->
			<div v-if="comment.isRoot && isResolved" class="d-flex">
				<v-tooltip left>
					<template #activator="{ on, attrs }">
						<v-btn
							text
							icon
							class="ml-auto"
							color="primary"
							v-bind="attrs"
							v-on="on"
							@click="$emit('reopen', threadId)"
						>
							<v-icon>mdi-checkbox-marked-circle-outline</v-icon>
						</v-btn>
					</template>
					{{ $t("review.reopen") }}
				</v-tooltip>
			</div>
		</div>

		<!-- Comment Body -->
		<p v-if="showCommentBody()" class="mb-0 font-weight-regular">
			{{ comment.content }}
		</p>
		<div v-else>
			<ChamaileonPrimaryTextInput
				hide-details
				class="font-weight-regular"
				:input-value="comment.content"
				:placeholder="$t('review.comments.edit')"
				@update:inputValue="updatedComment = $event"
			/>
			<div class="d-flex mt-4">
				<ChamaileonPrimaryButton
					class="mr-4"
					style="height: 36px"
					@click="$emit('update', comment, updatedComment)"
				>
					{{ $t("general.save") }}
				</ChamaileonPrimaryButton>
				<ChamaileonTextButton @click="$emit('closeEdit')">
					{{ $t("general.cancel") }}
				</ChamaileonTextButton>
			</div>
		</div>
	</div>
</template>

<script>
import { mapStores } from "pinia";
import { useReviewStore } from "@/store/reviewStore";

export default {
	name: "ReviewCommentListItem",
	props: {
		avatarUrl: {
			type: String,
			required: true,
		},
		comment: {
			type: Object,
			required: true,
		},
		collaborator: {
			type: Object,
			required: true,
		},
		collaboratorId: {
			type: String,
			required: true,
		},
		isEditing: {
			type: String,
			default: "",
		},
		isResolved: {
			type: Boolean,
			default: false,
		},
		isRoot: {
			type: Boolean,
			default: false,
		},
		threadId: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			updatedComment: "",
		};
	},
	computed: {
		...mapStores(useReviewStore),
		isSmallDevice() {
			return this.$vuetify.breakpoint.width < 768;
		},
		nameStyle() {
			if (this.isSmallDevice) return "";
			if (this.isResolved) return "max-width: 170px";
			if (this.canPerformActions) return `font-size: 12px; max-width: ${this.isRoot ? 134 : 170}px`;

			return "";
		},
		selectedComment() {
			return this.reviewStore.selectedComment;
		},
		comments() {
			return this.reviewStore.comments;
		},
		canPerformActions() {
			const isSelectedThread = this.selectedComment === this.threadId;
			const isAuthor = this.comment.collaboratorId === this.collaboratorId;

			return !!this.selectedComment
				&& (isSelectedThread)
				&& isAuthor;
		},
	},
	methods: {
		formatDate(createdAt) {
			const date = new Date(createdAt);
			const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
			const dateOptions = { day: "numeric", month: "short", year: "numeric" };

			const timeFormatted = new Intl.DateTimeFormat("en-US", timeOptions).format(date);
			const dateFormatted = new Intl.DateTimeFormat("en-US", dateOptions).format(date);

			return `${timeFormatted}, ${dateFormatted}`;
		},
		showCommentBody() {
			if (this.isEditing === null) return true;
			if (this.isEditing === this.comment._id) return false;
			return true;
		},
	},
};
</script>
