import Vue from "vue";
import * as Sentry from "@sentry/vue";

function initAndGetSentryConfig(sentryConfigUrl) {
	return new Promise(function (resolve, reject) {
		const scriptTag = document.createElement("script");
		// eslint-disable-next-line no-undef
		scriptTag.src = sentryConfigUrl;
		scriptTag.type = "text/javascript";
		// eslint-disable-next-line no-undef
		scriptTag.onload = () => resolve(sentryConfig);
		scriptTag.onerror = reject;
		document.head.appendChild(scriptTag);
	});
}

async function initSentry() {
	const parentWindowUrl = location.hostname;

	if (!parentWindowUrl.includes("chamaileon.io")) {
		return;
	}

	let environmentName = "dev";

	if (parentWindowUrl.includes("share.chamaileon.io")) {
		environmentName = "prod";
	} else if (parentWindowUrl.includes("share-staging.chamaileon.io")) {
		environmentName = "staging";
	}

	let sentryConfigUrl;

	// LOAD CONFIGURATION FILES
	if (environmentName !== "dev") {
		sentryConfigUrl = "https://cdn.chamaileon.io/assets/sentryConfig.js";
	}

	const sentryConfig = await initAndGetSentryConfig(sentryConfigUrl);

	if (sentryConfig && sentryConfig.excludedEnvs && !sentryConfig.excludedEnvs.includes(environmentName)) {
		Sentry.init({
			...sentryConfig.basicConfig,
			ignoreErrors: sentryConfig.ignoreErrors,
			denyUrls: sentryConfig.denyUrls,
			// add these to the front so we can overwrite them with local values if needed
			Vue,
			dsn: "https://7872ab93f35b6270b195f9dc0d1262dd@o267489.ingest.us.sentry.io/4507780435673088",
			integrations: [
				Sentry.browserTracingIntegration(),
				Sentry.replayIntegration(),
			],
			debug: false,
			// eslint-disable-next-line no-undef
			release: `chamaileon-share@${__APP_VERSION__}`,
			environment: environmentName,
			replaysSessionSampleRate: 0.1, // TODO move to config json when every plugin is updated to support replays
			replaysOnErrorSampleRate: 1.0, // TODO move to config json when every plugin is updated to support replays
		});
	}
}

export default initSentry;
