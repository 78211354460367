<template>
	<ChamaileonModal
		:show="show"
		max-width="560"
		@close="close"
	>
		<template #title>
			<h4 class="text-h6">
				{{ $t('review.reviewers') }}
			</h4>
		</template>
		<template #content>
			<v-sheet
				v-for="(item, idx) in collaborators"
				:key="item._id"
				class="d-flex align-center justify-space-between p-4 mb-4"
			>
				<div class="d-flex align-center" style="max-width: 80%;">
					<ReviewAvatar :collaborator="item" class="mr-4" />
					<v-sheet class="d-flex flex-column mb-0">
						<p class="text-subtitle-2 mb-0">
							{{ reviewStore.name({ collaborator: item }) }}
						</p>
						<p v-if="item?.userId" class="text-body-2 grey--text mb-0">
							{{ item.email }}
						</p>
					</v-sheet>
				</div>
				<p v-if="idx === 0" class="text-subtitle-2 mb-0">
					{{ $t('review.status.requester') }}
				</p>
				<p v-else-if="item?.role === 'editor'" class="text-subtitle-2 mb-0">
					{{ $t('review.status.editor') }}
				</p>
				<v-tooltip v-else left>
					<template #activator="{ on, attrs }">
						<v-icon
							:color="status(item).color"
							v-bind="attrs"
							v-on="on"
						>
							{{ status(item).icon }}
						</v-icon>
					</template>
					<span>{{ status(item).title }}</span>
				</v-tooltip>
			</v-sheet>
		</template>
		<template #buttons>
			<ChamaileonPrimaryButton @click="close">
				{{ $t('general.done') }}
			</ChamaileonPrimaryButton>
		</template>
	</ChamaileonModal>
</template>

<script>
import { mapStores } from "pinia";
import { useReviewStore } from "@/store/reviewStore";

export default {
	name: "ReviewModalStatus",
	props: {
		show: {
			type: Boolean,
			required: true,
		},
		collaborators: {
			type: Array,
			required: true,
		},
		comments: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapStores(useReviewStore),
	},
	methods: {
		close() {
			this.$emit("close");
		},
		status(item) {
			if (item.status === "approved") return { title: this.$t("review.status.approve"), icon: "mdi-check", color: "success" };

			const allComments = this.comments
				.filter(thread => !thread.resolved)
				.reduce((acc, thread) => {
					thread.comments.forEach(comment => acc.push(comment));
					return acc;
				}, []);
			const hasComments = allComments.some(comment => comment.collaboratorId === item._id);
			if (hasComments) return { title: this.$t("review.status.changeRequest"), icon: "mdi-sync", color: "error" };
			else return { title: this.$t("review.status.waiting"), icon: "mdi-timer-sand-complete", color: "warning" };
		},
	},
};
</script>
