import { defineStore } from "pinia";
import chamaileonFrontendModules from "@edmdesigner/chamaileon-frontend-modules";
import createApiConnectors from "@/utils/connectors/createApiConnectors";
import i18n from "@/plugins/i18n";

const connector = createApiConnectors([
	{
		key: "getEmail",
		method: "GET",
		route: {
			base: "folders-eu",
			path: "/review/workspace/:workspaceId/email/:emailId",
		},
	},
	{
		key: "getPreviewEmail",
		method: "GET",
		route: {
			base: "folders-eu",
			path: "/review/email/:previewHash",
		},
	},
	{
		key: "reportAbuse",
		method: "POST",
		route: {
			base: "folders-eu",
			path: "/review/workspace/:workspaceId/email/:emailId/report",
		},
	},
]);

const logger = chamaileonFrontendModules.storeModules.logger;

export const useDocumentStore = defineStore("document", {
	state: () => ({
		currentId: null,
		current: {},
	}),
	actions: {
		setCurrentId(_id) {
			this.currentId = _id;
		},
		setCurrent(item) {
			this.currentId = item?._id;
			this.current = item;
		},
		async loadOne({ workspaceId, emailId }) {
			try {
				const { result } = await connector.getEmail({
					ids: { workspaceId, emailId },
				});
				this.setCurrent(result);
			} catch (error) {
				const translationExists = i18n.te(`errorMessages.${error.message}`);
				const errorCode = translationExists ? error.message : "networkError";
				logger().logError({
					id: Date.now(),
					handled: true,
					code: error.message,
					source: "backend",
					stack: error,
					notification: i18n.t(`errorMessages.${errorCode}`),
					target: { type: "flashToast", timeout: 4000 },
				});
			}
		},
		async loadOneForPreview(previewHash) {
			try {
				const { result } = await connector.getPreviewEmail({
					ids: { previewHash },
				});
				this.setCurrent(result);
			} catch (error) {
				const translationExists = i18n.te(`errorMessages.${error.message}`);
				const errorCode = translationExists ? error.message : "networkError";
				logger().logError({
					id: Date.now(),
					handled: true,
					code: error.message,
					source: "backend",
					stack: error,
					notification: i18n.t(`errorMessages.${errorCode}`),
					target: { type: "flashToast", timeout: 4000 },
				});
			}
		},
		async reportAbuse(answers, clientData) {
			try {
				const { workspaceId, _id } = this.current;
				const report = {
					emailId: _id,
					emailTitle: this.current.title,
					issue: answers.issue,
					medium: answers.medium,
					details: answers.details,
				};

				await connector.reportAbuse({
					ids: { workspaceId, emailId: _id },
					body: { report, clientData, pageUrl: window.location.href },
				});

				logger().logSuccess({
					id: Date.now(),
					handled: true,
					source: "backend",
					notification: i18n.t("reportAbuse.success"),
					target: { type: "flashToast", timeout: 4000 },
				});

				return true;
			} catch (error) {
				const translationExists = i18n.te(`errorMessages.${error.message}`);
				const errorCode = translationExists ? error.message : "networkError";
				logger().logError({
					id: Date.now(),
					handled: true,
					code: error.message,
					source: "backend",
					stack: error,
					notification: i18n.t(`errorMessages.${errorCode}`),
					target: { type: "flashToast", timeout: 4000 },
				});
				return false;
			}
		},
	},
});
