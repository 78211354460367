<template>
	<v-sheet
		class="mb-4 pa-4 text-subtitle-2"
		style="border-radius: 6px; border: 1px solid var(--v-primary-base)"
	>
		<div class="d-flex align-center mb-4">
			<ReviewAvatar :collaborator="collaborator" />
			<div class="ml-2">
				<p class="mb-n1">
					{{ reviewStore.name({ collaborator }) }}
				</p>
				<p class="grey--text font-weight-regular mb-0">
					{{ formatDate(new Date()) }}
				</p>
			</div>
		</div>

		<ChamaileonPrimaryTextInput
			hide-details
			append-icon="mdi-send"
			class="font-weight-regular"
			:input-value="newComment"
			:placeholder="$t('review.comments.add')"
			@update:inputValue="newComment = $event"
			@append="addComment"
			@enterPressed="addComment"
		/>
	</v-sheet>
</template>

<script>
import { mapStores } from "pinia";
import { useReviewStore } from "@/store/reviewStore";

export default {
	name: "ReviewCommentAdd",
	props: {
		collaborators: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			newComment: "",
		};
	},
	computed: {
		...mapStores(useReviewStore),
		collaboratorId() {
			return this.reviewStore.collaboratorId;
		},
		collaborator() {
			return this.collaborators.find(c => c._id === this.collaboratorId);
		},
		hasCollaboratorApproved() {
			return this.collaborators?.some(el => el._id === this.reviewStore.collaboratorId && el.status === "approved");
		},
		isLoading() {
			return this.reviewStore.isLoading;
		},
	},
	methods: {
		formatDate(date) {
			const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
			const dateOptions = { day: "numeric", month: "short", year: "numeric" };

			const timeFormatted = new Intl.DateTimeFormat("en-US", timeOptions).format(date);
			const dateFormatted = new Intl.DateTimeFormat("en-US", dateOptions).format(date);

			return `${timeFormatted}, ${dateFormatted}`;
		},
		async addComment() {
			if (!this.newComment) return;
			if (this.isLoading) return;

			const newComment = {
				reviewId: this.reviewStore.current?._id,
				workspaceId: this.reviewStore.current?.workspaceId,
				collaboratorId: this.collaboratorId,
				eid: this.reviewStore.selectedEid,
				content: this.newComment,
			};

			if (this.hasCollaboratorApproved) {
				this.reviewStore.showDismissConfirmation = true;
				this.reviewStore.newComment = newComment;
			} else {
				await this.reviewStore.createComment(newComment);
			}

			this.newComment = "";
		},
	},
};
</script>
