import Vue from "vue";
import { defineStore } from "pinia";
import Pusher from "pusher-js/with-encryption";
import { useReviewStore } from "@/store/reviewStore.js";
import createApiConnectors from "@/utils/connectors/createApiConnectors.js";

const connector = createApiConnectors([
	{
		key: "pusherAuth",
		method: "POST",
		route: {
			base: "folders-eu",
			path: "/review/workspace/:workspaceId/collaborator/:collaboratorId/pusherAuth",
		},
	},
]);

export const usePusherStore = defineStore("pusher", {
	state: () => ({
		pusher: null,
		emailChannel: null,
		reviewChannel: null,
		outdatedEmail: false,
	}),
	actions: {
		init() {
			if (!this.pusher) this.initPusher();
			this.bindToChannels();
		},
		initPusher() {
			if (!this.pusher) {
				this.pusher = new Pusher(Vue.prototype.$chamaileon.environmentConfig.pusher.appId, {
					cluster: Vue.prototype.$chamaileon.environmentConfig.pusher.cluster,
					channelAuthorization: {
						customHandler: async ({ socketId, channelName }, pusherCallback) => {
							// https://pusher.com/docs/channels/using_channels/connection/#userauthcustomhandler-1319001424
							try {
								const { result } = await connector.pusherAuth({
									ids: {
										collaboratorId: useReviewStore()?.collaboratorId,
										workspaceId: useReviewStore().current?.workspaceId,
									},
									body: {
										socketId,
										channelName,
									},
								});
								this.socketId = socketId || null;
								pusherCallback(null, result);
							} catch (err) {
								console.error(err);
								pusherCallback("authFailed", {});
							}
						},
					},
				});
			}
		},
		bindToChannels() {
			if (!this.pusher) return;

			this.emailChannel = this.pusher.subscribe(`private-email-${useReviewStore()?.current?.emailId}`);
			this.reviewChannel = this.pusher.subscribe(`review-${useReviewStore()?.current?._id}`);

			this.emailChannel.bind("mutations", () => {
				this.outdatedEmail = true;
			});

			this.reviewChannel.bind("update:comments", async () => {
				await useReviewStore().loadComments();
			});

			this.reviewChannel.bind("update:collaborators", async () => {
				await useReviewStore().loadCollaborators();
			});
		},
	},
});
