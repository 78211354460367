<template>
	<div class="d-flex flex-row">
		<v-btn
			v-for="button in deviceButtons"
			:key="button.id"
			text
			:class="{
				'device-button text-none': true,
				'device-button-active': activeDevice === button.id,
			}"
			@click="$emit('update', button.id)"
		>
			<v-icon :color="activeDevice === button.id ? 'primary': 'black'">
				{{ button.icon }}
			</v-icon>
			<span
				v-if="!isSmallDevice"
				:class="{
					'ml-2': true,
					[activeDevice === button.id ? 'primary--text': 'black--text']: true,
				}"
			>
				{{ button.name }}
			</span>
		</v-btn>
	</div>
</template>

<script>
import { useReviewStore } from "@/store/reviewStore";
import { mapStores } from "pinia";

export default {
	name: "ReviewHeaderDeviceButtons",
	data() {
		return {
			deviceButtons: [
				{
					name: this.$t("general.mobile"),
					icon: "mdi-cellphone",
					id: "mobile",
				},
				{
					name: this.$t("general.desktop"),
					icon: "mdi-monitor",
					id: "desktop",
				},
			],
		};
	},
	computed: {
		...mapStores(useReviewStore),
		activeDevice() {
			return this.reviewStore.activeDevice;
		},
		isSmallDevice() {
			return this.$vuetify.breakpoint.width < 768;
		},
	},
};
</script>

<style scoped>
.device-button {
  letter-spacing: normal;
}

.device-button:first-of-type {
  margin-right: 8px;
}

.device-button-active:before {
  background-color: var(--v-primary-base);
  opacity: 0.1;
}
</style>
