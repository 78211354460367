export default (data, parentData) => {
	const filteredChanges = {};

	// Create a filtered object from updated data and current data. Add them in filteredChanges object with value&oldValue keys.
	Object.keys(data).forEach((key) => {
		if (Object.prototype.hasOwnProperty.call(parentData, key) && data[key] !== parentData[key]) {
			filteredChanges[key] = {
				value: data[key],
				oldValue: parentData[key],
			};
		}
		if (!Object.prototype.hasOwnProperty.call(parentData, key)) {
			filteredChanges[key] = {
				value: data[key],
				oldValue: "",
			};
		}
	});

	// Create 'actions' array of objects with the filtered changes, matching patch routes needs.
	const actions = Object.keys(filteredChanges).map((key) => {
		const change = filteredChanges[key];

		if (change.value === "") {
			return {
				type: "delete",
				path: key,
			};
		} else if (change.oldValue === "") {
			return {
				type: "add",
				path: key,
				value: change.value,
			};
		} else {
			return {
				type: "update",
				path: key,
				value: change.value,
				oldValue: change.oldValue,
			};
		}
	});

	actions.forEach((action) => {
		if (typeof action.oldValue === "string" && action.oldValue !== "") {
			if (typeof action.value !== "string") {
				throw new Error("Value must be of string type when oldValue is a string");
			}
		}
	});
	// Create the 'body' object with the 'actions' array
	const body = { actions };

	return { body };
};
