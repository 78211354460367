<template>
	<v-app-bar
		id="preview-header"
		app
		clipped-right
		elevation="0"
		:style="style"
	>
		<v-row
			align="center"
			justify="space-between"
			class="px-2"
		>
			<span>
				<v-img
					v-if="whiteLabelLogoExists"
					:src="whiteLabelLogoExists"
					contain
					max-width="200"
					max-height="48"
				/>
				<span v-else class="mt-2">
					<ChamaileonLogo />
				</span>
			</span>
			<span>
				<v-tooltip left>
					<template #activator="{ on }">
						<v-btn
							v-if="whitelabelStore?.current.showAbuseButton"
							text
							icon
							color="primary"
							v-on="on"
							@click="showReportAbuse = true"
						>
							<v-icon>mdi-help-circle-outline</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('store.plugin.preview.reportAbuse') }}</span>
				</v-tooltip>

				<v-tooltip left>
					<template #activator="{ on }">
						<v-btn
							text
							icon
							color="primary"
							:href="emailUrl"
							target="_blank"
							v-on="on"
						>
							<v-icon>mdi-export</v-icon>
						</v-btn>
					</template>
					<span>{{ $t('store.plugin.preview.openEmail') }}</span>
				</v-tooltip>
			</span>
		</v-row>

		<PreviewReportAbuse
			v-if="whitelabelStore?.current.showAbuseButton"
			:show="showReportAbuse"
			@close="showReportAbuse = false"
		/>
		<PreviewSecurityWarning
			:show="showSecurityWarning"
			@close="showSecurityWarning = false"
			@openAbuse="showReportAbuse = true"
		/>
	</v-app-bar>
</template>

<script>
import { useDocumentStore } from "@/store/documentStore";
import { useWhitelabelStore } from "@/store/whitelabelStore";
import { mapStores } from "pinia";
export default {
	name: "PreviewHeader",
	data() {
		return {
			showReportAbuse: false,
			showSecurityWarning: false,
		};
	},
	computed: {
		...mapStores(useDocumentStore, useWhitelabelStore),
		whiteLabelLogoExists() {
			if (this.whitelabelStore?.whitelabelExists && this.whitelabelStore.isCurrentValid && !this.whitelabelStore.isDefault) {
				return this.whitelabelStore.current.logoUrl;
			} else {
				return false;
			}
		},
		emailUrl() {
			if (!this.documentStore?.current) return "";
			if (!this.whitelabelStore.isDefault) return this.whitelabelStore.current.loginUrl;

			const url = window.location.href;
			const baseUrl = {
				local: "http://localhost:50008",
				test: "https://app-test.chamaileon.io",
				staging: "https://app-staging.chamaileon.io",
				prod: "https://app.chamaileon.io",
			};

			let env;
			if (url.includes("localhost")) env = "local";
			else if (url.includes("test")) env = "test";
			else if (url.includes("staging")) env = "staging";
			else env = "prod";

			const document = this.documentStore.current;
			return `${baseUrl[env]}/workspace/${document.workspaceId}/email/${document._id}`;
		},
		style() {
			return {
				"background-color": !this.whitelabelStore.isDefault ? "var(--v-header-base) !important" : "#FAFAFA !important",
				"border-bottom": !this.whitelabelStore.isDefault ? "1px solid var(--v-accent-base) !important" : "1px solid #E5E5E5 !important",
			};
		},
	},
	mounted() {
		if (!this.whitelabelStore?.current.showSecurityWarning) {
			this.showSecurityWarning = false;
			return;
		}

		if (localStorage && localStorage.trustedWorkspaces) {
			try {
				const trustedWorkspaces = localStorage.trustedWorkspaces;
				if (trustedWorkspaces.includes(this.documentStore.current.workspaceId)) {
					this.showSecurityWarning = false;
					return;
				}
			} catch (e) {
				console.error("Corrupted data");
			}
		}

		this.showSecurityWarning = true;
	},
};
</script>

<style scoped>
#preview-header {
	background-color: #FAFAFA !important;
	border-bottom: 1px solid #E5E5E5 !important;
}
</style>
