import { defineStore } from "pinia";
import createCrudConnector from "@/utils/connectors/createCrudConnector";

const connector = createCrudConnector({
	methods: [ "getOne" ],
	route: {
		base: "folders-eu",
		path: "/review/workspace/:workspaceId/whitelabel",
	},
});

export const useWhitelabelStore = defineStore("whitelabel", {
	state: () => ({
		current: {},
		whitelabelExists: false,
	}),
	actions: {
		setCurrent({ item }) {
			this.current = item;
		},
		setWhiteLabelExists(value) {
			this.whitelabelExists = value;
		},
		async load({ workspaceId }) {
			const { result: item } = await connector.getOne({ ids: { workspaceId } });
			this.setCurrent({ item });

			if (!this.isCurrentValid) throw new Error("Failed to load whitelabel");
			else this.setWhiteLabelExists(true);
		},
	},
	getters: {
		isCurrentValid() {
			const { logoUrl, domain, loginUrl, colorSchema } = this.current;
			return Boolean(logoUrl && domain && loginUrl && colorSchema?.background && colorSchema?.button && colorSchema?.border && colorSchema?.header);
		},
		isDefault() {
			return Boolean(this?.current?.isDefault);
		},
	},
});
