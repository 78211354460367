import { createConnector } from "./connectorBase";

export default function createApiConnectors(configs = []) {
	if (!Array.isArray(configs)) throw new Error("Configs must be an array");

	const connectors = {};

	const keys = configs.map(config => config.key);
	const uniqueKeys = new Set(keys);

	if (uniqueKeys.size !== keys.length) throw new Error("Connector keys must be unique");
	if (keys.every(key => typeof key !== "string")) throw new Error("Connector keys must be strings");

	configs.forEach((config) => {
		const { key, ...rest } = config;
		try {
			connectors[key] = createConnector(rest);
		} catch (err) {
			throw new Error(`Error creating connector for key: ${key}`);
		}
	});

	return connectors;
}
